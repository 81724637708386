import React from 'react'
import { StaticQuery, Link } from 'gatsby'
import SiteNavigation from './siteNavigation'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { t } from 'i18next'

const ProductHeader = props => (
  <>
    <SiteNavigation />
    <section className={`hero ${props.isGradient} is-large is-relative`}>
      {props.fluid && (
        <Img
          fluid={props.fluid}
          style={{
            position: 'absolute',
            overflow: 'hidden',
          }}
          imgStyle={{
            width: '100%',
            height: '100%',
            opacity: props.imageOpacity,
          }}
          className="image-wrapper-height-auto"
          alt={props.alt}
        />
      )}

      {props.animatedIllustration && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            overflow: 'hidden',
            bottom: 0,
            top: 0,
            height: '100%',
            width: '100%',
          }}
        >
          {props.animatedIllustration}
        </div>
      )}

      <div className="hero-body" style={{ zIndex: 5 }}>
        <div className="container">
          <div className="content is-large">
            <div className="content primaryblockquote">
              <h1
                className="title is-1 is-white capitalize"
              >
                {props.headerTitle}
              </h1>
              <h2 className="title is-5">{props.headerSubtitle}</h2>
              {props.buttonText && (
                <Link to={props.buttonLink} title={props.buttonLinkTitleTag} className="button is-light is-medium" target="_blank" rel="noopener noreferrer">{props.buttonText}</Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

ProductHeader.propTypes = {
  isGradient: PropTypes.string,
  imageOpacity: PropTypes.number,
}

ProductHeader.defaultProps = {
  isGradient: 'is-gradient',
  imageOpacity: 0.1,
}

export default ProductHeader
