import React from 'react'
import { graphql } from 'gatsby'
import Link from '../utilities/link'
import Section1 from '../components/sections/section1'
import Section6 from '../components/sections/section6'
import SiteNavigation from '../components/header/siteNavigation'
import CommonFooter from '../components/footer/commonFooter'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'
import { usePageContext } from '../pageContext'
import ProductHeader from '../components/header/productHeader'
import Card from '../components/common/card'

const Careers = ({ data }) => {
  const { t } = useTranslation();
  const { lang } = usePageContext();

  return (
    <>
      <Seo
        title={t('careers.seoTitle')}
        description={t('careers.seoDescription')}
        seoImage={data.lavoraConNoi.childImageSharp.resize.src}
      />
      <SiteNavigation />
      <ProductHeader
          imageOpacity="0.9"
          headerTitle={t('careers.title')}
          headerSubtitle={t('careers.subtitle')}
          fluid={data.lavoraConNoi.childImageSharp.fluid}
        />
      <div className='bg-gray-50'>
        <Section1
          section1Title={t('careers.introTitle')}
          section1Text={t('careers.introDesc')}
          alt={t('careers.heroImageAltTag')}
        />
      </div>
      <div className='py-12 md:py-24'>
        <div className="section">
          {data.allMarkdownRemark.group.map(({ fieldValue, edges }) => {
            return (
              <div className="md:flex md:space-x-16">
                <div className='md:w-1/3'>
                  <h3 className="text-3xl font-semibold border-b-2 border-green-400 pb-4">{fieldValue}</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {edges
                    .filter(({ node }) => {
                      return (
                        node.frontmatter.lang ===
                        lang
                      )
                    })
                    .map(({ node }) => {
                      return (
                        <Card
                          cardTitle={node.frontmatter.name}
                          cardSubtitle={node.frontmatter.headline}
                          to={`/${node.frontmatter.translatedPath}`}
                          button={t('careers.jobButton')}
                        />
                      )
                    })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Section6
        title={t('careers.featureTitle')}
        top={t('careers.feature1')}
        secondRow1={t('careers.feature2')}
        secondRow2={t('careers.feature3')}
        thirdRow1={t('careers.feature4')}
        thirdRow2={t('careers.feature5')}
        bottom1={t('careers.feature6')}
        bottom2={t('careers.feature7')}
      />
      <CommonFooter />
    </>
  )
}

export default Careers

export const careerQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { category: { eq: "career" } } }) {
      group(field: frontmatter___family) {
        fieldValue
        totalCount
        edges {
          node {
            frontmatter {
              name
              headline
              path
              lang
              translatedPath
            }
          }
        }
      }
    }
    lavoraConNoi: file(relativePath: { eq: "lavora-con-noi/lavora-con-noi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    microchipIcon: file(relativePath: { eq: "microchip.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ctaElectronics: file(relativePath: { eq: "ctaelectronics.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
